import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BsTelephone } from 'react-icons/bs';
import { LuMailQuestion } from 'react-icons/lu';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SectionLayout from 'layouts/SectionLayout';
import { ContactLink } from 'components/atoms/ContactLink/ContactLink';
import { maxDeviceSize } from 'utils/deviceSize';
import { getCompanyData, logError } from 'services/ResourceApiManager';
import { SocialMedia } from 'components/molecues/SocialMedia/SocialMedia';
import I18n from 'services/translation/I18n';

const ContactView = () => {
  const [company, setCompany] = useState('');

  const handleCompanyData = () => {
    getCompanyData()
      .then((response) => {
        setCompany(response.data);
      })
      .catch((error) => {
        logError(error, 'CVHI-01');
        toast.error(<I18n trans="error.message.companyDataError" />, {
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
      });
  };

  useEffect(() => {
    handleCompanyData();
  }, []);

  return (
    <SectionLayout>
      <StyledContactWrapper>
        <StyledTitle>
          <I18n trans="contact.title" />
        </StyledTitle>
        <StyledContactContentWrapper>
          <StyledContactDescription>
            <I18n trans="contact.description" />
          </StyledContactDescription>
          <StyledContactLinkContainer>
            <ContactLink
              href={`tel:${company.phoneNumber}`}
              alt="Phone number"
              Icon={BsTelephone}
              textAfter={company.phoneNumber}
              ariaLabel="Phone number"
            />
            <ContactLink
              href={`mailto:${company.companyEmail}`}
              alt="company email"
              Icon={LuMailQuestion}
              textAfter={company.companyEmail}
              ariaLabel="Company email"
            />
          </StyledContactLinkContainer>
          <SocialMedia facebook={company.facebook} instagram={company.instagram} />
        </StyledContactContentWrapper>
      </StyledContactWrapper>
      <ToastContainer />
    </SectionLayout>
  );
};

const StyledContactWrapper = styled.div``;
const StyledContactContentWrapper = styled.div`
  display: flex;
  padding: 2rem;
  align-items: center;
  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
  }
`;
const StyledContactLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledContactDescription = styled.p``;

const StyledTitle = styled.h2`
  font-size: 2rem;
  padding: 0 0 0 2rem;
`;

export default ContactView;
