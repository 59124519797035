import RequestApiBuilder from './RequestApiBuilder';

export const getCompanyData = () =>
  new RequestApiBuilder().setResource('companies').setId(process.env.REACT_APP_COMPANY_ID).get();

export const getFormsData = (params) =>
  new RequestApiBuilder().setResource('real-estates/get-forms').setParams(params).get();

export const getRealEstate = (id, params) =>
  /* prettier-ignore */
  new RequestApiBuilder()
    .setResource('real-estates')
    .setId(id)
    .setParams(params)
    .get();

export const getRealEstates = (params) =>
  new RequestApiBuilder().setResource('real-estates').setParams(params).get();

export const logError = (message, code) => {
  const body = {
    message: message.toString(),
    code,
  };

  return new RequestApiBuilder().setResource('error-logs').setBody(body).post();
};

export const getNews = (params) =>
  new RequestApiBuilder().setResource('news-posts').setParams(params).get();

/* prettier-ignore */
export const getNewsPost = (id, params) =>
  new RequestApiBuilder()
    .setResource('news-posts')
    .setId(id)
    .setParams(params)
    .get();

export const getTeams = (params) =>
  new RequestApiBuilder().setResource('teams').setParams(params).get();

export const getTeamMember = (id, params) =>
  /* prettier-ignore */
  new RequestApiBuilder()
    .setResource('teams')
    .setId(id)
    .setParams(params)
    .get();

export const getPartnerCompanies = () =>
  new RequestApiBuilder().setResource('partner-companies').get();
