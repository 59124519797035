import React from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import PropTypes from 'prop-types';

export const Hr = ({ margin }) => <StyledHr margin={margin} />;

Hr.propTypes = {
  margin: PropTypes.string,
};

Hr.defaultProps = {
  margin: 'true',
};

const StyledHr = styled.hr`
  border: 1px solid ${colors.glamourGold};
  margin-top: ${({ margin }) => (margin === 'true' ? '1rem' : '0')};
  margin-bottom: ${({ margin }) => (margin === 'true' ? '1rem' : '0')};
`;
