import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SectionLayout from 'layouts/SectionLayout';
import { maxDeviceSize } from 'utils/deviceSize';
import { getTeams, logError } from 'services/ResourceApiManager';
import I18n, { getLanguage } from 'services/translation/I18n';
import TeamCard from 'components/molecues/TeamCard/TeamCard';
import { getImageUrl } from 'services/ImageProvider';
import { ReloadContext } from 'context/ReloadContext';
import { MainLayout } from 'layouts/MainLayout';
import { Loading } from 'components/atoms/Loading/Loading';

const Team = () => {
  const [teams, setTeams] = useState('');
  const [loading, setLoading] = useState(true);
  const { reload } = useContext(ReloadContext);
  const lang = getLanguage();

  const handleTeams = () => {
    getTeams({ lang })
      .then((response) => {
        setLoading(false);
        setTeams(response.data['hydra:member']);
      })
      .catch((error) => {
        logError(error, 'THI-01');
        toast.error(<I18n trans="error.message.teamDataError" />, {
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
      });
  };

  useEffect(() => {
    handleTeams();
  }, [reload]);

  if (teams.length === 0) {
    return null;
  }

  if (loading) {
    return (
      <MainLayout>
        <SectionLayout>
          <Loading />
        </SectionLayout>
        <ToastContainer />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <SectionLayout>
        <StyledTeamWrapper>
          <StyledTitle>
            <I18n trans="team.title" />
          </StyledTitle>
          <StyledTeamContentWrapper>
            {teams.map((team) => (
              <TeamCard
                id={team.uuid}
                key={team.uuid}
                src={getImageUrl(team.avatar)}
                fullName={team.fullName}
                description={team.description}
              />
            ))}
          </StyledTeamContentWrapper>
        </StyledTeamWrapper>
        <ToastContainer />
      </SectionLayout>
    </MainLayout>
  );
};

const StyledTeamWrapper = styled.div``;
const StyledTeamContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 2rem;

  @media ${maxDeviceSize.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${maxDeviceSize.smallScreen} {
    grid-template-columns: repeat(3, 1fr);
    gap: 0rem;
  }
`;
const StyledTitle = styled.h2`
  font-size: 2rem;
  padding: 0 0 0 2rem;
`;

export default Team;
