import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Image } from 'components/molecues/Image/Image';
import { colors } from 'utils/colors';
import I18n from 'services/translation/I18n';

const ImageBox = ({ src, alt, title, secondary }) => (
  <StyledBoxContainer>
    <StyledTitle secondary={secondary}>
      <I18n trans={title} />
    </StyledTitle>
    <Image
      src={src}
      alt={alt}
      bgcolor="transparent"
      boxshadow="false"
      style={{ border: 'none' }}
      width="300"
      height="300"
      hover="false"
    />
  </StyledBoxContainer>
);

ImageBox.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
};

ImageBox.defaultProps = {
  secondary: false,
};

const StyledBoxContainer = styled.div`
  background-color: ${colors.white};
  padding-top: 1rem;
  margin: 1rem;
  text-align: center;
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  border: 1px solid rgba(228, 183, 116, 0.74);
  border-radius: 12px;
  img {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    transition: transform 0.3s ease;
  }
  transition: transform 0.3s ease;
`;

const StyledTitle = styled.h3`
  font-size: 1.2rem;
  text-align: center;
  padding-bottom: 1rem;
  color: ${({ secondary }) => (secondary ? colors.black : colors.true)};
`;
export default ImageBox;
