import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        font-family: Poppins, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        box-sizing: border-box;
        text-decoration: none;
    }
    /* For PartenrCompanyView slider */
    .slick-track {
        display: flex !important; 
    }
    .slick-slide {
        height: inherit !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
`;
