export const pl = {
  'navbar.news': 'Aktualności',
  'navbar.aboutUs': 'O nas',
  'navbar.realEstate': 'Nieruchomości',
  'navbar.realEstateValuation': 'Ekspertyza nieruchomości',
  'navbar.investitions': 'Inwestycje',
  'navbar.contact': 'Kontakt',
  'navbar.select.language.placeholder': 'Wybierz język',
  'home.hero.title': 'Twój nowy dom',
  'home.hero.subTitle': 'Odszukaj swój dom marzeń',
  'home.hero.button.searchRealEstate': 'Wyszukaj nieruchomość',
  'home.hero.button.sellRealEstate': 'Sprzedaj nieruchomość',
  'aboutUs.title': 'Kim jesteśmy?',
  'aboutUs.description.p1':
    'Licencjonowana pośredniczka z wieloletnim doświadczeniem na rynku nieruchomosci. Absolwentka Uniwersytetu Szczecińskiego na wydziale Nauk Ekonomicznych i zarządzania oraz studiów podyplomowych na Uniwerytecie Ekonomicznym w Poznaniu na kierunku Gospodarka nieruchomościami - Wycena nieruchomości oraz certyfikacja i audyt energetyczny budynków na WSKZ we Wrocławiu.',
  'aboutUs.description.p2':
    'Efektem wieloletniego doświadczenia oraz zdobytej wiedzy jest "HUK Invest Nieruchomości & Inwestycje". Nasza firma świadczy usługi obejmujące cały proces od obrotu nieruchomościami przez budowę, remontowanie, doradztwo inwestycyjne, przygotowywanie dokumentów energetycznych do pomocy w uzyskiwaniu kredytów związanych z nieruchomościami.',
  'aboutUs.description.p3':
    'Naszą misją jest stworzyć miejsce, w którym wszystkie świadczone usługi będą na najwyższym poziomie, a nasz profesjonalizm i zaangażowanie przełożą się na Państwa zadowolenie i satysfakcję.',
  'realEstateView.title': 'Znajdź swój nowy dom',
  'realEstateCard.showMore': 'Zobacz więcej',
  'realEstateView.button.showMoreRealEstate': 'Zobacz więcej nieruchomości',
  'navigator.title': 'Oto co możemy dla ciebie zrobić:',
  'navigator.button.buyRealEstate': 'Kup nieruchomość',
  'navigator.button.realEstateValuation': 'Ekspertyza nieruchomości',
  'navigator.button.epcOrder': 'Zamów certyfikat ŚCHE',
  room: 'pokój',
  rooms: 'pokoje',
  'investitions.title': 'Inwestycje',
  'investitions.title.marketAnalysis': 'Analiza rynku',
  'investitions.title.riskAndReturnAssessment': 'Ocena ryzyka i zwrotu',
  'investitions.title.investmentStrategy': 'Dobór strategii inwestycyjnej',
  'investitions.title.profitabilityAnalysis': 'Analizę opłacalności',
  'investitions.description':
    'Specjalizujemy się w świadczeniu usług doradczych związanych z inwestycjami na rynku nieruchomości. Działania z tym związane obejmują:',
  'investitions.description.marketAnalysis':
    'Przeprowadzamy kompleksową analizę aktualnej sytuacji na rynku nieruchomości, identyfikując trendy, prognozy cenowe oraz potencjalne obszary inwestycyjne. To pozwala klientom podejmować świadome decyzje inwestycyjne.',
  'investitions.description.riskAndReturnAssessment':
    'Przeprowadzamy analizę ryzyka związanego z konkretnymi inwestycjami, dostarczając klientom informacji na temat potencjalnych zagrożeń i możliwych zwrotów z inwestycji.',
  'investitions.description.investmentStrategy':
    'Na podstawie indywidualnych celów i preferencji klienta pomagamy w opracowaniu strategii inwestycyjnej dopasowanej do określonych potrzeb. Może to obejmować inwestycje krótko- lub długoterminowe, inwestycje w nieruchomości komercyjne lub mieszkalne, a także inne formy inwestycji na rynku nieruchomości.',
  'investitions.description.profitabilityAnalysis':
    'Przeprowadzamy szczegółową analizę opłacalności konkretnych inwestycji, uwzględniającą koszty zakupu, utrzymania i ewentualnej sprzedaży nieruchomości. To pomaga klientom zrozumieć potencjalne zyski i wyzwania związane z daną inwestycją.',
  'epcOrder.title': 'Świadectwo charakterystyki energetycznej',
  'epcOrder.description':
    'Świadectwo charakterystyki energetycznej to dokument, który określa wielkość zapotrzebowania na energię niezbędną do zaspokojenia potrzeb energetycznych związanych z użytkowaniem budynku lub części budynku, czyli energii na potrzeby ogrzewania i wentylacji, przygotowania ciepłej wody użytkowej, chłodzenia, a w przypadku budynków niemieszkalnych również oświetlenia. Obowiązek posiadania w określonych sytuacjach świadectwa charakterystyki energetycznej budynku lub części budynku wynika z prawa europejskiego. Celem wprowadzenia obowiązku sporządzania świadectw jest promowanie budownictwa efektywnego energetycznie i zwiększanie świadomości społecznej w zakresie możliwości uzyskania oszczędności energii w budynkach. Dzięki informacjom zawartym w świadectwie właściciel, najemca lub użytkownik budynku może określić orientacyjne roczne zapotrzebowanie na energię, a tym samym koszt utrzymania związany ze zużyciem energii.',
  'error.message.companyDataError':
    'Niestety nie udało się pobrać danych kontaktowych firmy, przepraszamy za niegodności i prosimy spróbować ponownie później [CVHI-01].',
  'error.message.realEstateDataError':
    'Niestety nie udało się pobrać nieruchomości, przepraszamy za niegodności i prosimy spróbować ponownie później [REDHI-01].',
  'error.message.searchFormError':
    'Niestety nie udało się pobrać parametrów do wyszukiwania, przepraszamy za niegodności i prosimy spróbować ponownie później [SFHI-01].',
  'error.message.newsDataError':
    'Niestety nie udało się pobrać aktulaności, przepraszamy za niegodności i prosimy spróbować ponownie później [NHI-01].',
  'error.message.teamDataError':
    'Niestety nie udało się pobrać danych o zespole, przepraszamy za niegodności i prosimy spróbować ponownie później [THI-01].',
  'error.message.partnerCompanyDataError':
    'Niestety nie udało się pobrać danych o partnerach biznesowych, przepraszamy za niegodności i prosimy spróbować ponownie później [PCHI-01].',
  'contact.title': 'Skontaktuj się z nami',
  'contact.description':
    'Wybierz formę kontaktu, która najbardziej Tobie odpowiada. Dzięki naszemu nowoczesnemu podejściu i elastyczności dostosowujemy się do potrzeb klienta.',
  'contact.socialMediaDescription': 'Obserwuj nas na mediach społecznościowych',
  'footer.copyRight': 'Copyright c 2024. Wszelkie prawa zastrzeżone - Webowi.pl',
  'realEstateValuation.title': 'Ekspertyza nieruchomości',
  'realEstateValuation.description':
    'To pojęcie podkreśla kompleksowe podejście do oceny nieruchomości, obejmujące zarówno aspekty finansowe (wycenę), jak i kwestie związane z efektywnością energetyczną (certyfikaty energetyczne). Ekspertyza nieruchomości obejmuje analizę wartości rynkowej, potencjału inwestycyjnego, a także ocenę efektywności energetycznej, co może być istotne dla właścicieli, inwestorów czy potencjalnych nabywców nieruchomości',
  'realEstateValuation.subTitle': 'Ekspertyza nieruchomosci w 4 prostych krokach',
  'valuation.step1.title': '1. Spotkajmy się',
  'valuation.step1.description':
    'Poznajmy się, pokaż nam swoją nieruchomość, omówmy wszystkie prawne aspekty i dopasujmy najlepszy plan sprzedaży',
  'valuation.step2.title': '2. Marketing',
  'valuation.step2.description':
    'Przygotujemy dla Ciebie najlepszy możliwy marketing i promocję Twojej nieruchomości w tym zdjęcia wyrażające najlepsze aspekty Twojej nieruchomości',
  'valuation.step3.title': '3. Prezentacja',
  'valuation.step3.description': 'Zaprezentujemy Twoją nieruchomość przyszłemu nabywcy',
  'valuation.step4.title': '4. Sprzedaż',
  'valuation.step4.description':
    'Po zainteresowaniu nabywcy, podpisujemy umowę przekazujemy klucze',
  'contactLink.realEstateCostCalculator': 'Kalkulator kosztów nieruchomości',
  'realEstate.realEstate.notFound':
    'Niestety nie znaleźliśmy żadnych nieruchomości spełniających podane kryteria.',
  'pageWrapper.findRealEstate': 'Wyszukaj nieruchomość',
  'pageWrapper.news': 'Aktualności',
  'selectBox.propertyType': 'Typ nieruchomości',
  'selectBox.location': 'Lokalizacja',
  'realEstateOfferDetail.title': 'Szczegóły oferty',
  'fromToInput.priceFrom': 'Cena od',
  'fromToInput.priceTo': 'Cena do',
  'fromToInput.areaFrom': 'Powierzchnia od',
  'fromToInput.areaTo': 'Powierzchnia do',
  'fromToInput.roomsFrom': 'Liczba pokoi od',
  'fromToInput.roomsTo': 'Liczba pokoi do',
  'searchForm.buttonTyped.find': 'Szukaj',
  'realEstateOfferDetail.price': 'Cena',
  'realEstateOfferDetail.pricePerM2': 'Cena za m2',
  'realEstateOfferDetail.area': 'Powierzchnia',
  'realEstateOfferDetail.roomsNumber': 'Liczba pokoi',
  'realEstateOfferDetail.numberOfFloors': 'Liczba pięter',
  'realEstateOfferDetail.floor': 'Piętro',
  'realEstateOfferDetail.code': 'Kod',
  'realEstateOfferDetail.location': 'Lokalizacja',
  'realEstateOfferDetail.type': 'Typ',
  'realEstateOfferDetail.condition': 'Stan',
  'realEstateDetail.description': 'Opis',
  createdAt: 'Data dodania: ',
  'team.title': 'Nasz zespół',
  'team.showMore': 'Zobacz więcej',
  'team.showAll': 'Zobacz cały zespół',
  'team.email': 'Email',
  'team.phoneNumber': 'Telefon',
  'partnerCompany.title': 'Firmy partnerskie',
};
