import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Navbar from '../components/organisms/Navbar/Navbar';
import { Footer } from '../components/organisms/Footer/Footer';

export const MainLayout = ({ children, navBarPositionAbsolute }) => (
  <StyledMainLayoutContainer>
    <Navbar absolute={navBarPositionAbsolute} />
    {children}
    <Footer />
  </StyledMainLayoutContainer>
);

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
  navBarPositionAbsolute: PropTypes.string,
};

MainLayout.defaultProps = {
  navBarPositionAbsolute: 'false',
};

const StyledMainLayoutContainer = styled.div``;
