import LinkService from '../Application/LinkService';

const LANG = 'language';
const DEFAULT_LANG = 'pl';

class LinkController {
  constructor() {
    this.linkService = new LinkService();
  }

  // eslint-disable-next-line class-methods-use-this
  #getLanguage = () =>
    window.localStorage.getItem(process.env.REACT_APP_LANGUAGE_COOKIE_NAME ?? LANG) ||
    (process.env.REACT_APP_DEFAULT_LANGUAGE ?? DEFAULT_LANG);

  getNavLinkItems = ({ lang = this.#getLanguage() } = {}) =>
    this.linkService.provideNavLinkItems({ lang });

  getRoutes = ({ lang = this.#getLanguage(), id } = {}) =>
    this.linkService.provideRoutes({ lang, id });

  getRouteByName = (name, { lang = this.#getLanguage(), id = null } = {}) =>
    this.getRoutes({ lang, id }).find((route) => route.name === name).route;

  getCurrentRouteByPath = (path, { lang = this.#getLanguage() } = {}) =>
    this.linkService.provideRouteByPathAndLang(path, { lang });
}

export default LinkController;
