import React from 'react';
import styled from 'styled-components';
import Step1MeetImage from 'assets/images/step-1-meet.webp';
import Step2MarketingImage from 'assets/images/step-2-marketing.webp';
import Step3PresentationImage from 'assets/images/step-3-presentation.webp';
import Step4SellImage from 'assets/images/step-4-sell.webp';
import ValutationStep from 'components/molecues/ValuationStep/ValutationStep';
import { colors } from 'utils/colors';

const ValuationSteps = () => (
  <StyledStepsWrapper>
    <StyledValuationStepContainer secondary>
      <ValutationStep
        image={Step1MeetImage}
        title="valuation.step1.title"
        description="valuation.step1.description"
      />
    </StyledValuationStepContainer>
    <StyledValuationStepContainer>
      <ValutationStep
        image={Step2MarketingImage}
        title="valuation.step2.title"
        description="valuation.step2.description"
      />
    </StyledValuationStepContainer>
    <StyledValuationStepContainer secondary>
      <ValutationStep
        image={Step3PresentationImage}
        title="valuation.step3.title"
        description="valuation.step3.description"
      />
    </StyledValuationStepContainer>
    <StyledValuationStepContainer>
      <ValutationStep
        image={Step4SellImage}
        title="valuation.step4.title"
        description="valuation.step4.description"
      />
    </StyledValuationStepContainer>
  </StyledStepsWrapper>
);

const StyledStepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledValuationStepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.black};
  background-color: ${({ secondary }) => (secondary ? colors.lightBlack : colors.white)};
  color: ${({ secondary }) => (secondary ? colors.white : colors.black)};
  padding: 4rem calc((100vw - 1500px) / 2);
`;

export default ValuationSteps;
