import React from 'react';
import styled from 'styled-components';
import { MainLayout } from 'layouts/MainLayout';
import AnalysisImage from 'assets/images/investitions/Analisys.webp';
import RiskAssessmentImage from 'assets/images/investitions/RiskAssessment.webp';
import InvestmentStrategyImage from 'assets/images/investitions/InvestmentStrategy.webp';
import AnalysisChartImage from 'assets/images/investitions/ProfitabilityAnalisys.webp';
import { maxDeviceSize } from 'utils/deviceSize';
import { colors } from 'utils/colors';
import ImageBox from 'components/molecues/ImageBox/ImageBox';
import I18n from 'services/translation/I18n';

const Investitions = () => (
  <MainLayout>
    <CustomSectionLayout>
      <StyledTitle>
        <I18n trans="investitions.title" />
      </StyledTitle>
      <StyledSectionContainer>
        <StyledMainDesription>
          <I18n trans="investitions.description" />
        </StyledMainDesription>
        <StyledDescriptionContainer secondary>
          <ImageBox
            secondary
            src={AnalysisImage}
            alt="Market analysis"
            title="investitions.title.marketAnalysis"
          />
          <StyledParagraph>
            <I18n trans="investitions.description.marketAnalysis" />
          </StyledParagraph>
        </StyledDescriptionContainer>
        <StyledDescriptionContainer>
          <ImageBox
            src={RiskAssessmentImage}
            alt="Risk and return assessment"
            title="investitions.title.riskAndReturnAssessment"
          />
          <StyledParagraph>
            <I18n trans="investitions.description.riskAndReturnAssessment" />
          </StyledParagraph>
        </StyledDescriptionContainer>
        <StyledDescriptionContainer secondary>
          <ImageBox
            secondary
            src={InvestmentStrategyImage}
            alt="Investment strategy"
            title="investitions.title.investmentStrategy"
          />
          <StyledParagraph>
            <I18n trans="investitions.description.investmentStrategy" />
          </StyledParagraph>
        </StyledDescriptionContainer>
        <StyledDescriptionContainer>
          <ImageBox
            src={AnalysisChartImage}
            alt="Profitability analysis"
            title="investitions.title.profitabilityAnalysis"
          />
          <StyledParagraph>
            <I18n trans="investitions.description.profitabilityAnalysis" />
          </StyledParagraph>
        </StyledDescriptionContainer>
      </StyledSectionContainer>
    </CustomSectionLayout>
  </MainLayout>
);

const StyledTitle = styled.h2`
  margin-top: 2rem;
  font-size: 2rem;
  text-align: center;
  letter-spacing: 0.8;
  padding: 0 0 2rem 2rem;
  @media ${maxDeviceSize.tablet} {
    padding: 0;
  }
  color: ${({ secondary }) => (secondary === 'true' ? colors.white : colors.black)};
`;

const CustomSectionLayout = styled.div`
  position: relative;
  width: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledSectionContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media ${maxDeviceSize.tablet} {
    width: 100%;
    flex-direction: column;
  }
`;

const StyledDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  color: ${colors.black};
  background-color: ${({ secondary }) => (secondary ? colors.lightBlack : colors.white)};
  color: ${({ secondary }) => (secondary ? colors.white : colors.black)};
  @media ${maxDeviceSize.tablet} {
    width: 100%;
    flex-direction: column;
  }
  padding: 4rem calc((100vw - 1500px) / 2);
`;

const StyledParagraph = styled.p`
  padding: 1rem;
  font-size: 1.2rem;
  line-height: 1.5;
`;

const StyledMainDesription = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;
  padding: 1rem;
  padding-bottom: 2rem;
`;

export default Investitions;
