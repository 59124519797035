import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ReloadContext = createContext();

export const ReloadProvider = ({ children }) => {
  const [reload, setReload] = useState(false);

  const reloadComponent = () => {
    setReload((prevReload) => !prevReload);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ReloadContext.Provider value={{ reload, reloadComponent }}>{children}</ReloadContext.Provider>
  );
};

ReloadProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
};

export const useReload = () => useContext(ReloadContext);
