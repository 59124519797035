import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
// import { maxDeviceSize } from 'utils/deviceSize';
import { getNews, logError } from 'services/ResourceApiManager';
import { Loading } from 'components/atoms/Loading/Loading';
import PageWrapper from 'components/organisms/PageWrapper/PageWrapper';
import Pagination from 'components/molecues/Pagination/Pagination';
import { toast } from 'react-toastify';
import I18n, { getLanguage } from 'services/translation/I18n';
import { ReloadContext } from 'context/ReloadContext';
import NewsCard from 'components/molecues/NewsCard/NewsCard';
import { getImageUrl } from 'services/ImageProvider';

const News = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [newsPerPage] = useState(6);
  const { reload } = useContext(ReloadContext);
  const lang = getLanguage();

  const getAllNews = () => {
    getNews({ lang, 'order[createdAt]': 'desc' })
      .then((response) => {
        const { data } = response;
        setLoading(false);
        setNews(data['hydra:member']);
      })
      .catch((error) => {
        logError(error, 'NHI-01');
        toast.error(<I18n trans="error.message.newsDataError" />, {
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
      });
  };

  useEffect(() => {
    getAllNews();
  }, [reload]);

  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNews = news.slice(indexOfFirstNews, indexOfLastNews);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return (
      <PageWrapper>
        <Loading />
      </PageWrapper>
    );
  }
  return (
    <PageWrapper title="pageWrapper.news">
      <StyledNewsWrapper>
        {
          // eslint-disable-next-line
          currentNews &&
            currentNews.map((newsPost) => (
              <NewsCard
                key={newsPost.uuid}
                id={newsPost.uuid}
                src={getImageUrl(newsPost.image)}
                title={newsPost.title}
                description={newsPost.description}
                createdAt={newsPost.createdAt}
              />
            ))
        }
      </StyledNewsWrapper>
      {news.length > newsPerPage && (
        <Pagination itemsPerPage={newsPerPage} totalItems={news.length} paginate={paginate} />
      )}
    </PageWrapper>
  );
};

const StyledNewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default News;
