import axios from 'axios';

class RequestApiBuilder {
  constructor() {
    this.mainUrl = process.env.REACT_APP_BACKEND_URL;
    this.resource = '';
    this.id = '';
    this.params = {};
    this.axios = this.generateAxios();
  }

  generateAxios() {
    return axios.create({
      baseURL: this.mainUrl,
      timeout: 30000,
    });
  }

  setResource(resource) {
    this.resource = resource;
    return this;
  }

  setId(id) {
    this.id = id;
    return this;
  }

  setParams(params) {
    this.params = params;
    return this;
  }

  setBody(body) {
    this.body = body;
    return this;
  }

  post() {
    return this.axios.post(`${this.resource}`, this.body);
  }

  get() {
    if (Object.keys(this.params).length) {
      Object.entries(this.params).forEach((param, index) => {
        if (index === 0) {
          this.params = `${param[0]}=${param[1]}`;
        } else {
          this.params = `${this.params}&${param[0]}=${param[1]}`;
        }
      });

      if (this.id !== '') {
        return this.axios.get(`${this.resource}/${this.id}?${this.params}`, {});
      }
      return this.axios.get(`${this.resource}?${this.params}`, {});
    }

    return this.axios.get(`${this.resource}/${this.id}`);
  }
}

export default RequestApiBuilder;
