class RouteResponse {
  constructor({ name, route }) {
    this.name = name;
    this.route = route;
  }

  getName() {
    return this.name;
  }

  getRoute() {
    return this.route;
  }
}

export default RouteResponse;
