import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { colors } from '../../../utils/colors';

export const Loading = ({ message }) => (
  <StyledLoadingWrapper>
    <ReactLoading color={colors.glamourGold} />
    <p>{message}</p>
  </StyledLoadingWrapper>
);

Loading.propTypes = {
  message: PropTypes.string,
};

Loading.defaultProps = {
  message: '',
};

const StyledLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
`;
