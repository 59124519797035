export const prepareCategoriesFilter = (filterParams, e) => {
  const params = filterParams;
  const category = e.value.match(/\[(.*?)\]/)[1] || '';
  const value = e.value.replace(/\[.*?\]/g, '');
  if (value === '') {
    delete params[category];
  } else {
    params[category] = value;
  }
  return params;
};

/* prettier-ignore */
export const prepareRangeFilterParams = (filterParams, property) => {
  const parms = filterParams;
  if (
    parms[`${property}[gte]`] !== undefined
    && parms[`${property}[gte]`] !== ''
    && parms[`${property}[lte]`] !== undefined
    && parms[`${property}[lte]`] !== ''
  ) {
    parms[`${property}[between]`] = `${parms[`${property}[gte]`]}..${parms[`${property}[lte]`]}`;
  } else {
    delete parms[`${property}[between]`];
  }

  return parms;
};
