import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import LinkController from 'DDD/Link/Ui/LinkController';
import Home from 'pages/Home';
import About from 'pages/About';
import Contact from 'pages/Contact';
import RealEstateDetail from 'pages/RealEstateDetail';
import Investitions from 'pages/Investitions';
import EPCOrder from 'pages/EPCOrder';
import { pages } from 'constans/pages';
import { v4 } from 'uuid';
import { ReloadProvider } from 'context/ReloadContext';
import RealEstate from './RealEstate';
import RealEstateValuation from './RealEstateValuation';
import News from './News';
import NewsDetail from './NewsDetail';
import TeamMemberDetail from './TeamMemberDetail';
import Team from './Team';

const Pages = () => {
  const linkController = new LinkController();
  return (
    <ReloadProvider>
      <AnimatePresence mode="wait">
        <Routes>
          <Route index path="/" element={<Home />} />
          {linkController.getRouteByName(pages.news, { lang: null }).map((path) => (
            <Route path={path} element={<News />} key={v4()} />
          ))}
          {linkController.getRouteByName(pages.aboutUs, { lang: null }).map((path) => (
            <Route path={path} element={<About />} key={v4()} />
          ))}
          {linkController.getRouteByName(pages.realEstate, { lang: null }).map((path) => (
            <Route path={path} element={<RealEstate />} key={v4()} />
          ))}
          {linkController.getRouteByName(pages.realEstateDetail, { lang: null }).map((path) => (
            <Route path={path} element={<RealEstateDetail />} key={v4()} />
          ))}
          {linkController.getRouteByName(pages.realEstateValuation, { lang: null }).map((path) => (
            <Route path={path} element={<RealEstateValuation />} key={v4()} />
          ))}
          {linkController.getRouteByName(pages.epcOrder, { lang: null }).map((path) => (
            <Route path={path} element={<EPCOrder />} key={v4()} />
          ))}
          {linkController.getRouteByName(pages.investitions, { lang: null }).map((path) => (
            <Route path={path} element={<Investitions />} key={v4()} />
          ))}
          {linkController.getRouteByName(pages.contact, { lang: null }).map((path) => (
            <Route path={path} element={<Contact />} key={v4()} />
          ))}
          {linkController.getRouteByName(pages.newsDetail, { lang: null }).map((path) => (
            <Route path={path} element={<NewsDetail />} key={v4()} />
          ))}
          {linkController.getRouteByName(pages.teamMemberDetail, { lang: null }).map((path) => (
            <Route path={path} element={<TeamMemberDetail />} key={v4()} />
          ))}
          {linkController.getRouteByName(pages.team, { lang: null }).map((path) => (
            <Route path={path} element={<Team />} key={v4()} />
          ))}
        </Routes>
      </AnimatePresence>
    </ReloadProvider>
  );
};

export default Pages;
