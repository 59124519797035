import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import PropTypes from 'prop-types';
import { getImageUrl } from 'services/ImageProvider';

const GallerySlider = ({ images }) => {
  const mapImagesToGalleryItems = (imagesForGalleryItems) =>
    imagesForGalleryItems.map((image) => ({
      original: getImageUrl(image.image),
      thumbnail: getImageUrl(image.image),
    }));

  return <ImageGallery items={mapImagesToGalleryItems(images)} />;
};

GallerySlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
    }),
  ).isRequired,
};

export default GallerySlider;
