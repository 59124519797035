import JsonToArrayDeserializer from 'DDD/Kernel/serializers/JsonToArrayDeserializer';
// import { LinkRepository } from 'DDD/Link/Domain/LinkRepository';
import linkData from 'assets/linkData.json';

// to do - implement repository
class LinkProvider {
  // eslint-disable-next-line class-methods-use-this
  getLinks = () => JsonToArrayDeserializer(linkData);

  getNavLinkItems = () => this.getLinks().filter((link) => link.showInNav);

  // eslint-disable-next-line class-methods-use-this
  getLinkByName = (name) => this.getLinks().find((link) => link.name === name);
}

export default LinkProvider;
