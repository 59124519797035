import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonTyped } from '../../atoms/ButtonTyped/ButtonTyped';

const Pagination = ({ itemsPerPage, totalItems, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <StyledPaginationContainer>
      {pageNumbers.map((number) => (
        <ButtonTyped onClick={() => paginate(number)} type="button" text={number} small="true" />
      ))}
    </StyledPaginationContainer>
  );
};

Pagination.propTypes = {
  itemsPerPage: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
};

const StyledPaginationContainer = styled.nav`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding: 1rem;
`;

export default Pagination;
