import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import SectionLayout from 'layouts/SectionLayout';
import RealEstateCard from 'components/molecues/RealEstateCard/RealEstateCard';
import { Button } from 'components/atoms/Button/Button';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';
import { getRealEstates } from 'services/ResourceApiManager';
import { Loading } from 'components/atoms/Loading/Loading';
import I18n, { getLanguage } from 'services/translation/I18n';
import { ReloadContext } from 'context/ReloadContext';
import { getImageUrl } from 'services/ImageProvider';

const RealEstateView = () => {
  const [realEstates, setRealEstates] = useState();
  const [loading, setLoading] = useState(true);
  const { reload } = useContext(ReloadContext);

  const retrieveRealEstates = () => {
    const lang = getLanguage();
    getRealEstates({ itemsPerPage: 3, lang, 'order[createdAt]': 'desc' }).then((response) => {
      const { data } = response;
      setRealEstates(data['hydra:member'].slice(0, 3));
      setLoading(false);
    });
  };

  useEffect(() => {
    retrieveRealEstates();
  }, [reload]);

  if (loading) {
    return (
      <SectionLayout secondary="true">
        <StyledTitle>
          <I18n trans="realEstateView.title" />
        </StyledTitle>
        <Loading />
        <StyledButtonMoreContainer>
          <div />
          <div />
          <StyledButtonMoreWrapper>
            <Button href="/nieruchomosci" text="realEstateView.button.showMoreRealEstate" />
          </StyledButtonMoreWrapper>
        </StyledButtonMoreContainer>
      </SectionLayout>
    );
  }

  return (
    <SectionLayout secondary="true">
      <StyledTitle>
        <I18n trans="realEstateView.title" />
      </StyledTitle>
      <StyledRealEstateWrapper>
        {realEstates.map((realEstate) => (
          <RealEstateCard
            key={realEstate.uuid}
            id={realEstate.uuid}
            src={getImageUrl(realEstate.mainImage)}
            title={realEstate.title}
            price={String(realEstate.price)}
            roomsNumber={realEstate.roomCount}
            area={realEstate.area}
            currency={realEstate.currency}
          />
        ))}
      </StyledRealEstateWrapper>
      <StyledButtonMoreContainer>
        <div />
        <div />
        <StyledButtonMoreWrapper>
          <Button href="/nieruchomosci" text="realEstateView.button.showMoreRealEstate" />
        </StyledButtonMoreWrapper>
      </StyledButtonMoreContainer>
    </SectionLayout>
  );
};

const StyledTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  letter-spacing: 0.8;
  padding: 1rem 0 3rem 0;
  color: ${colors.white};

  @media ${maxDeviceSize.tablet} {
    font-size: 1.5rem;
  }
`;

const StyledRealEstateWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
  }
`;

const StyledButtonMoreContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
`;

const StyledButtonMoreWrapper = styled.div`
  margin: auto;
`;

export default RealEstateView;
