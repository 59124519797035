import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DetailListItem } from 'components/atoms/DetailListItem/DetailListItem';
import I18n from 'services/translation/I18n';
import TeamCardRealEstate from 'components/molecues/TeamCardRealEstate/TeamCardRealEstate';
import { maxDeviceSize } from 'utils/deviceSize';

const RealEstateOfferDetails = ({ price, priceForMeter, realEstate, currency }) => {
  const priceForMetterSuffix = `${currency}/m2`;

  return (
    <StyledDetailContainer>
      <StyledDetailTitle>
        <I18n trans="realEstateOfferDetail.title" />
      </StyledDetailTitle>
      <DetailListItem name="realEstateOfferDetail.price" value={price} />
      <DetailListItem
        name="realEstateOfferDetail.pricePerM2"
        value={priceForMeter}
        sufix={priceForMetterSuffix}
      />
      {realEstate.area && (
        <DetailListItem name="realEstateOfferDetail.area" value={realEstate.area} sufix="m2" />
      )}
      {realEstate.roomCount !== 0 && (
        <DetailListItem name="realEstateOfferDetail.roomsNumber" value={realEstate.roomCount} />
      )}
      {realEstate.floorCount && (
        <DetailListItem name="realEstateOfferDetail.numberOfFloors" value={realEstate.floorCount} />
      )}
      {realEstate.floor && (
        <DetailListItem name="realEstateOfferDetail.floor" value={realEstate.floor} />
      )}
      {realEstate.code && (
        <DetailListItem name="realEstateOfferDetail.code" value={realEstate.code} />
      )}
      {realEstate.location && (
        <DetailListItem name="realEstateOfferDetail.location" value={realEstate.location} />
      )}
      {realEstate.type && (
        <DetailListItem name="realEstateOfferDetail.type" value={realEstate.type} />
      )}
      {realEstate.condition && (
        <DetailListItem name="realEstateOfferDetail.condition" value={realEstate.condition} />
      )}
      {realEstate.team && (
        <TeamCardRealEstate
          firstName={realEstate.team.firstname}
          lastName={realEstate.team.lastname}
          email={realEstate.team.email}
          phoneNumber={realEstate.team.phoneNumber}
          imageSrc={realEstate.team.avatar}
        />
      )}
    </StyledDetailContainer>
  );
};

RealEstateOfferDetails.propTypes = {
  price: PropTypes.string.isRequired,
  priceForMeter: PropTypes.string.isRequired,
  realEstate: PropTypes.objectOf(PropTypes.string).isRequired,
  currency: PropTypes.string,
};

RealEstateOfferDetails.defaultProps = {
  currency: 'PLN',
};

const StyledDetailContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: flex;
  flex-direction: column;

  @media ${maxDeviceSize.tablet} {
    align-items: center;
  }
`;
const StyledDetailTitle = styled.h2`
  font-size: 1.2rem;
`;

export default RealEstateOfferDetails;
