import React from 'react';
import { MainLayout } from 'layouts/MainLayout';
import SectionLayout from 'layouts/SectionLayout';
import I18n from 'services/translation/I18n';

const EPCOrder = () => (
  <MainLayout>
    <SectionLayout>
      <h1>
        <I18n trans="epcOrder.title" /> Świadectwo charakterystyki energetycznej
      </h1>
      <p>
        <I18n trans="epcOrder.description" />
      </p>
    </SectionLayout>
  </MainLayout>
);

export default EPCOrder;
