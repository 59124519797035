import React from 'react';
import { MainLayout } from 'layouts/MainLayout';
import AboutView from 'views/AboutView';

const About = () => (
  <MainLayout>
    <AboutView />
  </MainLayout>
);

export default About;
