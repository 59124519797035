import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { v4 } from 'uuid';
import I18n from 'services/translation/I18n';
import { maxDeviceSize } from '../../../utils/deviceSize';
import { NavLinkItem } from '../../atoms/NavLinkItem/NavLinkItem';
import Logo from '../../../assets/images/Logo.png';
import { colors } from '../../../utils/colors';
import LinkController from '../../../DDD/Link/Ui/LinkController';
import { LanguageSelect } from '../LanguageSelect/LanguageSelect';

/* eslint no-confusing-arrow: "off" */
/* eslint implicit-arrow-linebreak: "off" */
/* eslint function-paren-newline: "off" */
export const NavLinkItemsList = () => {
  const linkController = new LinkController();
  return (
    <StyledListItems>
      {linkController.getNavLinkItems().map((navLinkItem) =>
        navLinkItem.alt !== 'Logo' ? (
          <NavLinkItem
            href={navLinkItem.href}
            text={navLinkItem.label}
            alt={navLinkItem.alt}
            key={v4()}
          />
        ) : (
          <StyledLogoWrapper key={v4()}>
            <StyledLogoContainer to="/">
              <StyledLogo src={Logo} alt="logo" />
              <StyledBrandNameContainer>
                <StyledBrandName>Huk Invest</StyledBrandName>
                <StyledBrandNameDescription>
                  <I18n trans="navbar.realEstate" />
                </StyledBrandNameDescription>
              </StyledBrandNameContainer>
            </StyledLogoContainer>
          </StyledLogoWrapper>
        ),
      )}
      <LanguageSelect />
    </StyledListItems>
  );
};

const StyledListItems = styled.ul`
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  max-width: 1300px;
  margin: auto;

  @media ${maxDeviceSize.phone} {
    flex-direction: column;
  }
`;

const StyledLogoWrapper = styled.div`
  z-index: 300;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const StyledLogoContainer = styled(Link)`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  @media ${maxDeviceSize.tablet} {
    flex-direction: row;
  }
`;

const StyledLogo = styled.img`
  width: 75px;

  @media ${maxDeviceSize.smallScreen} {
    width: 75px;
  }

  @media ${maxDeviceSize.phone} {
    width: 40px;
  }
`;

const StyledBrandName = styled.p`
  color: ${colors.white};
  @media ${maxDeviceSize.tablet} {
    font-size: 2rem;
  }

  @media ${maxDeviceSize.phone} {
    font-size: 1.2rem;
  }
`;

const StyledBrandNameContainer = styled.div`
  display: none;
  @media ${maxDeviceSize.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
`;

const StyledBrandNameDescription = styled.p`
  font-size: 1rem;
  margin-top: -0.5rem;
  color: ${colors.white};
  @media ${maxDeviceSize.phone} {
    display: none;
  }
`;
