import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SectionLayout from 'layouts/SectionLayout';
import { getPartnerCompanies, logError } from 'services/ResourceApiManager';
import I18n from 'services/translation/I18n';
import Slider from 'react-slick';
import { Image } from 'components/molecues/Image/Image';
import { getImageUrl } from 'services/ImageProvider';
import { colors } from 'utils/colors';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PartnerCompanyView = () => {
  const [partnerCompanies, setPartnerCompanies] = useState('');

  const provideSettings = (partnerCompaniesLength) => ({
    dots: false,
    infinite: partnerCompaniesLength !== 1,
    centerMode: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  const handlePartnerCompanies = () => {
    getPartnerCompanies()
      .then((response) => {
        setPartnerCompanies(response.data['hydra:member']);
      })
      .catch((error) => {
        logError(error, 'PCHI-01');
        toast.error(<I18n trans="error.message.partnerCompanyDataError" />, {
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
      });
  };

  useEffect(() => {
    handlePartnerCompanies();
  }, []);

  if (partnerCompanies.length === 0) {
    return null;
  }

  return (
    <SectionLayout secondary="true">
      <StyledParterCompaniesWrapper>
        <StyledTitle>
          <I18n trans="partnerCompany.title" />
        </StyledTitle>
        <Slider {...provideSettings(partnerCompanies.length)}>
          {partnerCompanies.map((partnerCompany) => (
            <StyledImageContainer key={partnerCompany.uuid}>
              <Image
                src={getImageUrl(partnerCompany.logo)}
                alt={partnerCompany.uuid}
                width="100%"
                height="100%"
                style={{
                  border: 'none',
                  boxshadow: 'none',
                  transform: 'none',
                  borderRadius: '12px',
                }}
              />
            </StyledImageContainer>
          ))}
        </Slider>
      </StyledParterCompaniesWrapper>
      <ToastContainer />
    </SectionLayout>
  );
};

const StyledParterCompaniesWrapper = styled.div`
  padding: 2rem;
  width: 100%;
  display: block;
`;
const StyledTitle = styled.h2`
  font-size: 2rem;
  padding: 0 0 0 2rem;
  color: ${colors.white};
  margin-bottom: 2rem;
`;

const StyledImageContainer = styled.div`
  padding: 1rem;
  width: 300;
`;

export default PartnerCompanyView;
