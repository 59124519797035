import React from 'react';
import Hero from 'views/Hero';
import { MainLayout } from 'layouts/MainLayout';
import AboutView from 'views/AboutView';
import Navigator from 'views/Navigator';
import RealEstateView from 'views/RealEstateView';
import ContactView from 'views/ContactView';
import InvestitionView from 'views/InvestitionView';
import TeamView from 'views/TeamView';
import PartnerCompanyView from 'views/PartnerCompanyView';

const Home = () => (
  <MainLayout navBarPositionAbsolute="true">
    <Hero />
    <AboutView />
    <RealEstateView />
    <Navigator />
    <InvestitionView secondary="true" />
    <TeamView />
    <PartnerCompanyView />
    <ContactView />
  </MainLayout>
);

export default Home;
