import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MainLayout } from 'layouts/MainLayout';
import { Image } from 'components/molecues/Image/Image';
import { colors } from 'utils/colors';
import SectionLayout from 'layouts/SectionLayout';
import { maxDeviceSize } from 'utils/deviceSize';
import { getNewsPost, logError } from 'services/ResourceApiManager';
import { Loading } from 'components/atoms/Loading/Loading';
import I18n, { getLanguage } from 'services/translation/I18n';
import { ReloadContext } from 'context/ReloadContext';
import { Hr } from 'components/atoms/Hr/Hr';
import { getImageUrl } from 'services/ImageProvider';
import parse from 'html-react-parser';

const NewsDetail = () => {
  const { id } = useParams();
  const [newsPost, setNewsPost] = useState({});
  const [loading, setLoading] = useState(true);
  const { reload } = useContext(ReloadContext);

  const retrieveNewsPost = (newsPostId) => {
    const lang = getLanguage();
    getNewsPost(newsPostId, { lang })
      .then((response) => {
        setLoading(false);
        const { data } = response;
        setNewsPost(data);
      })
      .catch((error) => {
        logError(error, 'NDHI-01');
        toast.error(<I18n trans="error.message.newsDataError" />, {
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
      });
  };

  useEffect(() => {
    retrieveNewsPost(id);
  }, [id, reload]);
  if (loading) {
    return (
      <MainLayout>
        <SectionLayout>
          <Loading />
        </SectionLayout>
        <ToastContainer />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <SectionLayout>
        <StyledHeadingContainer>
          <StyledHeading>{newsPost.title}</StyledHeading>
        </StyledHeadingContainer>
        <StyledMainImageContainer>
          <Image
            src={getImageUrl(newsPost.image)}
            alt={newsPost.name}
            bgcolor={colors.white}
            width="100%"
            height="100%"
          />
        </StyledMainImageContainer>
        <Hr />
        <StyledDescriptionWrapper>
          <StyledDescriptionContainer>
            <StyledDescription>{parse(newsPost.description) || ''}</StyledDescription>
          </StyledDescriptionContainer>
        </StyledDescriptionWrapper>
      </SectionLayout>
      <ToastContainer />
    </MainLayout>
  );
};

const StyledHeadingContainer = styled.div`
  text-align: center;
`;
const StyledHeading = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;
const StyledDescriptionWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
  justify-content: flex-start;

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }
`;

const StyledDescriptionContainer = styled.div`
  font-size: 1.2rem;
`;
const StyledDescription = styled.p``;
const StyledMainImageContainer = styled.div``;

export default NewsDetail;
