import React from 'react';
import { MainLayout } from 'layouts/MainLayout';
import ContactView from 'views/ContactView';

const Contact = () => (
  <MainLayout>
    <ContactView />
  </MainLayout>
);

export default Contact;
