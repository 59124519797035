import LinkFactory from '../Domain/LinkFactory';
import LinkProvider from '../Infastructure/Rest/LinkProvider';
import NavLinkItemResponse from './NavLinkItemResponse';
import RouteResponse from './RouteResponse';

class LinkService {
  constructor() {
    this.linkRepository = new LinkProvider();
    this.linkFactory = new LinkFactory();
  }

  #generateLinksForNav = () => {
    const links = this.linkRepository.getNavLinkItems();

    return links.map((link) => this.linkFactory.createLink(link.name, link.label, link.routes));
  };

  #generateLinks = () =>
    this.linkRepository
      .getLinks()
      .map((link) => this.linkFactory.createLink(link.name, link.label, link.routes));

  // eslint-disable-next-line class-methods-use-this
  #prepareRoute = (link, { lang = null, id = null }) => {
    if (id !== null) {
      return link.getRouteForLanguageAndId(lang, id);
    }
    return lang !== null ? link.getRouteForLanguage(lang) : link.showRoutes();
  };

  provideRouteByPathAndLang = (path, { lang = null }) => {
    const links = this.#generateLinks();
    const link = links.find((l) => l.showRoutes().includes(path));

    if (link === undefined) {
      return path;
    }

    return this.#prepareRoute(link, { lang });
  };

  provideNavLinkItems = ({ lang = null }) => {
    const links = this.#generateLinksForNav();

    return links.map(
      (link) =>
        new NavLinkItemResponse({
          href: this.#prepareRoute(link, { lang }),
          label: link.showLabel(),
          alt: link.showName(),
          key: link.id,
          issidebar: true,
        }),
    );
  };

  provideRoutes = ({ lang = null, id = null }) => {
    const links = this.#generateLinks();

    return links.map(
      (link) =>
        new RouteResponse({
          name: link.showName(),
          route: this.#prepareRoute(link, { lang, id }),
        }),
    );
  };
}

export default LinkService;
