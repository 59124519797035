class Link {
  constructor(id, name, label, routes) {
    this.id = id;
    this.name = name;
    this.label = label;
    this.routes = routes;
  }

  showLabel() {
    return this.label;
  }

  getRouteForLanguage(language) {
    if (!this.routes[language]) {
      throw new Error(`Route for language ${language} not found`);
    }
    return this.routes[language];
  }

  getRouteForLanguageAndId(language, id) {
    if (id === null) {
      throw new Error('Id is required to generate route with id');
    }
    if (!this.routes[language]) {
      throw new Error(`Route for language ${language} not found`);
    }

    return this.routes[language].replace(':id', id);
  }

  showRoutes() {
    return Object.values(this.routes);
  }

  showName() {
    return this.name;
  }
}

export default Link;
