import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import { translateString } from 'services/translation/I18n';

export const Input = ({ id, placeHolder, type, step, handleSearchParams }) => (
  <StyledInput
    id={id}
    placeholder={translateString(placeHolder)}
    type={type}
    step={step}
    onChange={(e) => handleSearchParams(e)}
  />
);

Input.propTypes = {
  id: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  type: PropTypes.string,
  step: PropTypes.number,
  handleSearchParams: PropTypes.func.isRequired,
};

Input.defaultProps = {
  type: 'text',
  step: null,
};

const StyledInput = styled.input`
  padding: 0.5rem;
  outline: 0 !important;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: #000;
  color: hsl(0, 0%, 50%);
  margin-left: 2px;
  margin-right: 2px;
  width: 100%;
  font: inherit;
  color: ${colors.black};
  -webkit-transition: all 100ms;
  transition: all 100ms;

  &:focus {
    border: 1px solid ${colors.glamourGold};
    outline: 1px solid ${colors.glamourGold};
  }
`;
