import React from 'react';
import styled from 'styled-components';
import LinkController from 'DDD/Link/Ui/LinkController';
import videoBg from 'assets/video/background-flat.mp4';
import flatPosterBg from 'assets/images/background-flat-poster.webp';
import { Button } from 'components/atoms/Button/Button';
import { maxDeviceSize } from 'utils/deviceSize';
import { pages } from 'constans/pages';
import I18n from 'services/translation/I18n';

const Hero = () => {
  const linkController = new LinkController();
  return (
    <StyledHeroWrapper>
      <StyledVideoBackground src={videoBg} muted autoPlay loop playsInline poster={flatPosterBg} />
      <StyledContentContainer>
        <StyledTitle>
          <I18n trans="home.hero.title" />
        </StyledTitle>
        <p>
          <I18n trans="home.hero.subTitle" />
        </p>
        <StyledButtonsWrapper>
          <Button
            href={linkController.getRouteByName(pages.realEstate)}
            text="home.hero.button.searchRealEstate"
          />
          <Button
            href={linkController.getRouteByName(pages.realEstateValuation)}
            text="home.hero.button.sellRealEstate"
          />
        </StyledButtonsWrapper>
      </StyledContentContainer>
    </StyledHeroWrapper>
  );
};

const StyledHeroWrapper = styled.div`
  width: 100%;
  height: 80vh;
`;

const StyledVideoBackground = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledContentContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.8rem;
  z-index: 1000;

  @media ${maxDeviceSize.smallScreen} {
    font-size: 1.4rem;
  }

  @media ${maxDeviceSize.verySmallScreen} {
  }

  @media ${maxDeviceSize.tablet} {
    font-size: 1.2rem;
  }
`;

const StyledTitle = styled.h1`
  padding: 1rem;
`;

const StyledButtonsWrapper = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: space-around;

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
  }
`;

export default Hero;
