import React from 'react';
import styled from 'styled-components';
import LinkController from 'DDD/Link/Ui/LinkController';
import NavigatorBackgroundImage from 'assets/images/navigatorbackground.webp';
import { colors } from 'utils/colors';
import { Button } from 'components/atoms/Button/Button';
import { maxDeviceSize } from 'utils/deviceSize';
import { pages } from 'constans/pages';
import I18n from 'services/translation/I18n';

const Navigator = () => {
  const linkController = new LinkController();
  return (
    <StyledNavigatorWrapper image={NavigatorBackgroundImage}>
      <StyledNavigatorContainer>
        <StyledSectionTitle>
          <I18n trans="navigator.title" />
        </StyledSectionTitle>
        <StyledButtonsWrapper>
          <Button
            href={linkController.getRouteByName(pages.realEstate)}
            text="navigator.button.buyRealEstate"
          />
          <Button
            href={linkController.getRouteByName(pages.realEstateValuation)}
            text="navigator.button.realEstateValuation"
          />
          <Button
            href={linkController.getRouteByName(pages.epcOrder)}
            text="navigator.button.epcOrder"
          />
        </StyledButtonsWrapper>
      </StyledNavigatorContainer>
    </StyledNavigatorWrapper>
  );
};

const StyledNavigatorWrapper = styled.div`
  background: url(${NavigatorBackgroundImage}) repeat fixed 100%;
  overflow: hidden;
  height: 500px;
  background-size: cover;
`;

const StyledNavigatorContainer = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0.6);
  color: ${colors.white};
  width: 60%;
  margin: auto;
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  border-radius: 12px;
  @media ${maxDeviceSize.tablet} {
    width: 80%;
  }
`;

const StyledSectionTitle = styled.h2`
  margin-top: 8rem;
  text-align: center;
  font-size: 2.5rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  font-weight: 500;
  padding: 2rem;

  @media ${maxDeviceSize.smallScreen} {
    font-size: 1rem;
    margin-top: 3rem;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  @media ${maxDeviceSize.smallScreen} {
    flex-direction: column;
  }
`;

export default Navigator;
