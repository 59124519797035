import React from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';
import LinkController from 'DDD/Link/Ui/LinkController';
import { NavLinkItem } from '../../atoms/NavLinkItem/NavLinkItem';
import { maxDeviceSize } from '../../../utils/deviceSize';
import { LanguageSelect } from '../LanguageSelect/LanguageSelect';

/* eslint no-confusing-arrow: "off" */
/* eslint implicit-arrow-linebreak: "off" */
/* eslint function-paren-newline: "off" */
export const DropDownNavLinkItemsList = () => {
  const linkController = new LinkController();
  return (
    <StyledDropdownMenu>
      {linkController.getNavLinkItems().map((navLinkItem) =>
        navLinkItem.alt !== 'Logo' ? (
          <StyledNavItemDropdown key={v4()}>
            <NavLinkItem
              href={navLinkItem.href}
              text={navLinkItem.label}
              alt={navLinkItem.alt}
              dropdown="true"
            />
          </StyledNavItemDropdown>
        ) : (
          <span />
        ),
      )}
      <LanguageSelect issidebar="true" />
    </StyledDropdownMenu>
  );
};

const StyledDropdownMenu = styled.div`
  @keyframes slideDown {
    0% {
      transform: translateY(-8%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  display: none;
  position: absolute;
  height: 320px;
  right: 2rem;
  top: 120px;
  width: 300px;
  background-color: rgba(37, 36, 40, 0.4);

  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  animation: slideDown 0.5s ease-in-out;
  z-index: 9999;

  @media ${maxDeviceSize.tablet} {
    display: block;
  }

  @media ${maxDeviceSize.phone} {
    left: 2rem;
    width: unset;
    top: 100px;
  }
`;

const StyledNavItemDropdown = styled.li`
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
