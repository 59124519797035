import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MainLayout } from 'layouts/MainLayout';
import SectionLayout from 'layouts/SectionLayout';
import I18n from 'services/translation/I18n';

const PageWrapper = ({ title, children }) => (
  <MainLayout>
    <StyledRealEstateContainer>
      <StyledTitle>
        <I18n trans={title} />
      </StyledTitle>
      <SectionLayout>{children}</SectionLayout>
    </StyledRealEstateContainer>
    <ToastContainer />
  </MainLayout>
);

PageWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
};

PageWrapper.defaultProps = {
  children: null,
};

const StyledRealEstateContainer = styled.div`
  margin-top: 2rem;
`;

const StyledTitle = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
`;

export default PageWrapper;
