import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SectionLayout from 'layouts/SectionLayout';
import AnalysisImage from 'assets/images/investitions/Analisys.webp';
import RiskAssessmentImage from 'assets/images/investitions/RiskAssessment.webp';
import InvestmentStrategyImage from 'assets/images/investitions/InvestmentStrategy.webp';
import AnalysisChartImage from 'assets/images/investitions/ProfitabilityAnalisys.webp';
import { maxDeviceSize } from 'utils/deviceSize';
import { colors } from 'utils/colors';
import ImageBox from 'components/molecues/ImageBox/ImageBox';
import I18n from 'services/translation/I18n';

const InvestitionView = ({ secondary }) => (
  <SectionLayout secondary={secondary}>
    <StyledTitle secondary={secondary}>
      <I18n trans="investitions.title" />
    </StyledTitle>
    <StyledSectionContainer>
      <StyledBoxesContainer>
        <ImageBox
          src={AnalysisImage}
          alt="Market analysis"
          title="investitions.title.marketAnalysis"
        />
        <ImageBox
          src={RiskAssessmentImage}
          alt="Risk and return assessment"
          title="investitions.title.riskAndReturnAssessment"
        />
      </StyledBoxesContainer>
      <StyledBoxesContainer>
        <ImageBox
          src={InvestmentStrategyImage}
          alt="Investment strategy"
          title="investitions.title.investmentStrategy"
        />
        <ImageBox
          src={AnalysisChartImage}
          alt="Profitability analysis"
          title="investitions.title.profitabilityAnalysis"
        />
      </StyledBoxesContainer>
    </StyledSectionContainer>
  </SectionLayout>
);

InvestitionView.propTypes = {
  secondary: PropTypes.string,
};

InvestitionView.defaultProps = {
  secondary: 'false',
};

const StyledTitle = styled.h2`
  font-size: 2rem;
  text-align: center;
  letter-spacing: 0.8;
  padding: 0 0 4rem 2rem;
  color: ${({ secondary }) => (secondary === 'true' ? colors.white : colors.black)};
`;

const StyledSectionContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ secondary }) => (secondary === 'true' ? colors.white : colors.black)};

  @media ${maxDeviceSize.smallScreen} {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledBoxesContainer = styled.div`
  display: flex;
  @media ${maxDeviceSize.phone} {
    flex-direction: column;
  }
`;

export default InvestitionView;
