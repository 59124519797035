class NavLinkItemResponse {
  constructor({ href, label, alt, key }) {
    this.href = href;
    this.label = label;
    this.alt = alt;
    this.key = key;
    this.issidebar = false;
  }
}

export default NavLinkItemResponse;
