import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FaDoorOpen } from 'react-icons/fa';
import { FaVectorSquare } from 'react-icons/fa6';
import { BsCash } from 'react-icons/bs';
import { LuPenSquare } from 'react-icons/lu';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';

export const ShortRealEstateIconDescription = ({
  price,
  roomcount,
  priceForMeter,
  area,
  code,
  currency,
}) => {
  const priceForMetterSuffix = `${currency}/m2`;
  return (
    <StyledShortDetailWrapper>
      <StyledShortIconDetailContainer>
        {roomcount !== '0' && roomcount !== 'undefined' && (
          <StyledIconDataContainer>
            <FaDoorOpen style={{ color: colors.glamourGold }} /> {roomcount}
          </StyledIconDataContainer>
        )}
        <StyledIconDataContainer>
          <FaVectorSquare style={{ color: colors.glamourGold }} /> {area} m2
        </StyledIconDataContainer>
        <StyledIconDataContainer>
          <BsCash style={{ color: colors.glamourGold }} /> {priceForMeter} {priceForMetterSuffix}
        </StyledIconDataContainer>
        <StyledIconDataContainer>
          <LuPenSquare style={{ color: colors.glamourGold }} /> {code}
        </StyledIconDataContainer>
      </StyledShortIconDetailContainer>
      <StyledPriceContainer>{price}</StyledPriceContainer>
    </StyledShortDetailWrapper>
  );
};

ShortRealEstateIconDescription.propTypes = {
  price: PropTypes.string.isRequired,
  roomcount: PropTypes.string,
  area: PropTypes.string.isRequired,
  priceForMeter: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  currency: PropTypes.string,
};

ShortRealEstateIconDescription.defaultProps = {
  roomcount: '0',
  currency: 'PLN',
};

const StyledShortDetailWrapper = styled.div`
  display: flex;
  border: 1px solid ${colors.glamourGold};
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const StyledShortIconDetailContainer = styled.div`
  display: flex;
  justify-content: space-around;
  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
  }
`;

const StyledPriceContainer = styled.div`
  font-size: 1.2rem;
  margin-right: 1rem;
  padding: 1rem;
`;

const StyledIconDataContainer = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  margin-left: 1rem;
  padding: 1rem;

  @media ${maxDeviceSize.tablet} {
    font-size: 1rem;
    padding: 0.2rem;
  }
`;
