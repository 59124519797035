import React from 'react';
import styled from 'styled-components';
import SectionLayout from 'layouts/SectionLayout';
import { Image } from 'components/molecues/Image/Image';
import AboutImage from 'assets/images/about.jpg';
import { maxDeviceSize } from 'utils/deviceSize';
import I18n from 'services/translation/I18n';

const AboutView = () => (
  <SectionLayout>
    <StyledTitle>
      <I18n trans="aboutUs.title" />
    </StyledTitle>
    <StyledSectionContainer>
      <StyledDescription>
        <StyledParagraph>
          <I18n trans="aboutUs.description.p1" />
        </StyledParagraph>
        <StyledParagraph>
          <I18n trans="aboutUs.description.p2" />
        </StyledParagraph>
        <StyledParagraph>
          <I18n trans="aboutUs.description.p3" />
        </StyledParagraph>
      </StyledDescription>
      <StyledImageContainer>
        <Image
          src={AboutImage}
          alt="About us office room view"
          width="100%"
          height="100%"
          hover="false"
          style={{ borderRadius: '10px' }}
        />
      </StyledImageContainer>
    </StyledSectionContainer>
  </SectionLayout>
);

const StyledTitle = styled.h2`
  font-size: 2rem;
  letter-spacing: 0.8;
  padding: 0 0 0 2rem;
`;

const StyledSectionContainer = styled.div`
  display: flex;

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledDescription = styled.div`
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  padding: 4rem 4rem 4rem 2rem;
`;

const StyledParagraph = styled.p`
  padding: 1rem 0;
`;

const StyledImageContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`;
export default AboutView;
