import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';
import I18n, { changeLanguage } from 'services/translation/I18n';
import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';
import LinkController from 'DDD/Link/Ui/LinkController';
import { useReload } from 'context/ReloadContext';

export const LanguageSelect = ({ issidebar }) => {
  const [currentRoute, setCurrentRoute] = React.useState(null);
  const { reloadComponent } = useReload();
  const linkController = new LinkController();
  const location = useLocation();
  const options = [
    { value: 'pl', label: 'Polski' },
    { value: 'de', label: 'Deutsch' },
  ];

  const colourStyles = {
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? colors.blue : null,
      color: colors.lightBlack,
    }),
  };

  const provideLanguagePlaceHolder = () => {
    const language = window.localStorage.getItem('language');
    if (language === 'pl') return 'Polski';
    if (language === 'de') return 'Deutsch';
    return <I18n trans="navbar.select.language.placeholder" />;
  };

  const handleLanguageChange = (selectedOption) => {
    window.localStorage.setItem('language', selectedOption.value);
    changeLanguage(selectedOption.value);
    setCurrentRoute(linkController.getCurrentRouteByPath(location.pathname));
    reloadComponent();
  };

  return (
    <StyledSelectContainer issidebar={issidebar}>
      {currentRoute && <Navigate to={currentRoute} />}
      <Select
        styles={colourStyles}
        options={options}
        placeholder={provideLanguagePlaceHolder()}
        onChange={handleLanguageChange}
        aria-label="Language select"
      />
    </StyledSelectContainer>
  );
};

LanguageSelect.propTypes = {
  issidebar: PropTypes.string,
};

LanguageSelect.defaultProps = {
  issidebar: 'false',
};

const StyledSelectContainer = styled.div`
  position: ${({ issidebar }) => (issidebar === 'true' ? 'auto' : 'absolute')};
  right: 25px;
  top: 60px;
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 99999;

  @media ${maxDeviceSize.tablet} {
    display: ${({ issidebar }) => (issidebar === 'true' ? 'flex' : 'none')};
    justify-content: center;
  }
`;
