import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SectionLayout from 'layouts/SectionLayout';
import { maxDeviceSize } from 'utils/deviceSize';
import { getTeamMember, logError } from 'services/ResourceApiManager';
import I18n, { getLanguage } from 'services/translation/I18n';
import { ReloadContext } from 'context/ReloadContext';
import { getImageUrl } from 'services/ImageProvider';
import { Loading } from 'components/atoms/Loading/Loading';
import { useParams } from 'react-router-dom';
import { MainLayout } from 'layouts/MainLayout';
import parse from 'html-react-parser';

const TeamMemberDetail = () => {
  const [teamMember, setTeamMember] = useState({});
  const [loading, setLoading] = useState(true);
  const { reload } = useContext(ReloadContext);
  const { id } = useParams();

  const retreiveTeamMember = (teamMemberId) => {
    const lang = getLanguage();
    getTeamMember(teamMemberId, { lang })
      .then((response) => {
        setLoading(false);
        const { data } = response;
        setTeamMember(data);
      })
      .catch((error) => {
        logError(error, 'TMDHI-01');
        toast.error(<I18n trans="error.message.teamDataError" />, {
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
      });
  };

  useEffect(() => {
    retreiveTeamMember(id);
  }, [id, reload]);

  if (loading) {
    return (
      <MainLayout>
        <SectionLayout>
          <Loading />
        </SectionLayout>
        <ToastContainer />
      </MainLayout>
    );
  }

  const { avatar, fullName, description } = teamMember;

  return (
    <MainLayout>
      <SectionLayout>
        <StyledTeamWrapper>
          <StyledContentWrapper>
            <StyledImage src={getImageUrl(avatar)} alt={fullName} />
            <StyledTextWrapper>
              <StyledName>{fullName}</StyledName>
              <StyledDescription>{parse(description || '')}</StyledDescription>
            </StyledTextWrapper>
          </StyledContentWrapper>
        </StyledTeamWrapper>
        <ToastContainer />
      </SectionLayout>
    </MainLayout>
  );
};

const StyledTeamWrapper = styled.div`
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
`;

const StyledContentWrapper = styled.div`
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledImage = styled.img`
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
`;

const StyledTextWrapper = styled.div`
  max-width: 600px;
`;

const StyledName = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
`;

const StyledDescription = styled.div`
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
`;

export default TeamMemberDetail;
