import React from 'react';

import styled from 'styled-components';
import { MainLayout } from 'layouts/MainLayout';
import ValuationSteps from 'components/organisms/ValuationsSteps/ValuationSteps';
import I18n from 'services/translation/I18n';

const RealEstateValuation = () => (
  <MainLayout>
    <CustomSectionLayout>
      <StyledExpertiseDescription>
        <StyledSmallTitle>
          <I18n trans="realEstateValuation.title" />
        </StyledSmallTitle>
        <I18n trans="realEstateValuation.description" />
      </StyledExpertiseDescription>
      <StyledTitle>
        <I18n trans="realEstateValuation.subTitle" />
      </StyledTitle>
      <ValuationSteps />
    </CustomSectionLayout>
  </MainLayout>
);

const CustomSectionLayout = styled.div`
  position: relative;
  width: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledExpertiseDescription = styled.div`
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;
`;

const StyledTitle = styled.h2`
  font-size: 1.5rem;
  text-align: center;
  padding: 2rem;
`;
const StyledSmallTitle = styled.h3`
  font-size: 1.8rem;
  text-align: center;
  padding: 2rem;
`;

export default RealEstateValuation;
