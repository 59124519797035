import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';
import I18n from 'services/translation/I18n';

export const SelectBox = ({ options, id, name, placeHolder, handleSearchParams }) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: colors.black,
      borderColor: colors.glamourGold,
      backgroundColor: state.isSelected ? colors.glamourGold : colors.white,
      '&:hover': {
        border: `1px solid ${colors.glamourGold}`,
        borderColor: colors.glamourGold,
      },
      '&:focus': {
        outline: `1px solid ${colors.glamourGold} !important`,
        border: `1px solid ${colors.glamourGold}`,
        borderColor: colors.glamourGold,
      },
    }),
    control: (provided) => ({
      ...provided,
      color: colors.black,
      border: `1px solid ${colors.glamourGold}`,
      boxShadow: 'none',
    }),
  };

  SelectBox.propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeHolder: PropTypes.string.isRequired,
    handleSearchParams: PropTypes.func.isRequired,
  };

  return (
    <StyledSelect
      id={id}
      name={name}
      options={options}
      placeholder={<I18n trans={placeHolder} />}
      styles={customStyles}
      autoComplete="new-password"
      onChange={(e) => handleSearchParams(e)}
      isSearchable
    />
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
  margin: 0.1rem 0.5rem 0.1rem 0.5rem;

  @media ${maxDeviceSize.tablet} {
    width: 100%;
  }
`;
