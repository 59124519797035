import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image } from 'components/molecues/Image/Image';
import { Button } from 'components/atoms/Button/Button';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';
import LinkController from 'DDD/Link/Ui/LinkController';
import { pages } from 'constans/pages';
import I18n from 'services/translation/I18n';
import parse from 'html-react-parser';

const NewsCard = ({ id, title, src, description, createdAt }) => {
  const linkController = new LinkController();

  const isDescriptionTruncated = description.length > 300;

  const truncatedDescription =
    description.length > 100 ? `${description.substring(0, 300)}...` : description;

  return (
    <StyledCardContainer>
      {createdAt && (
        <StyledUpatedAtContainer>
          <I18n trans="createdAt" />
          {createdAt}
        </StyledUpatedAtContainer>
      )}
      <StyledTitleWrapper>{title}</StyledTitleWrapper>
      <StyledContentContainer>
        {!String(src).includes('undefined') && (
          <StyledImageContainer
            to={
              isDescriptionTruncated
                ? linkController.getRouteByName(pages.newsDetail, { id })
                : null
            }
            isDescriptionTruncated={isDescriptionTruncated}
          >
            <Image
              src={src}
              alt={title}
              width="100%"
              height="100%"
              style={{ width: '100%', boxshadow: 'none', border: 'none', transform: 'none' }}
            />
          </StyledImageContainer>
        )}
        <StyledShortDescriptionWrapper>
          <StyledDescriptionContainer>
            <StyledDescription>{parse(truncatedDescription || '')}</StyledDescription>
          </StyledDescriptionContainer>
          {isDescriptionTruncated && (
            <StyledButtonWrapper>
              <Button
                href={linkController.getRouteByName(pages.newsDetail, { id })}
                text="realEstateCard.showMore"
              />
            </StyledButtonWrapper>
          )}
        </StyledShortDescriptionWrapper>
      </StyledContentContainer>
    </StyledCardContainer>
  );
};

NewsCard.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  createdAt: PropTypes.string,
};

NewsCard.defaultProps = {
  src: '',
  description: '',
  createdAt: '',
};

const StyledDescription = styled.p`
  text-align: center;
`;

const StyledUpatedAtContainer = styled.div`
  text-align: right;
  font-size: 0.8rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
`;

const StyledCardContainer = styled.div`
  width: 100%;
  border: 1px solid ${colors.rottenGreen};
  background-color: ${({ bgcolor }) => bgcolor || colors.white};
  margin: 1rem;
  box-shadow: 8px 8px 16px -14px rgba(66, 68, 90, 1);
  @media ${maxDeviceSize.tablet} {
    margin: 2rem;
    width: 80%;
  }
`;

const StyledImageContainer = styled(Link)`
  padding: 1rem;
  width: 50%;
  height: 50%;

  &:hover {
    transition: 0.5s;
    cursor: ${({ isDescriptionTruncated }) => (isDescriptionTruncated ? 'pointer' : 'auto')};
  }
`;

const StyledTitleWrapper = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
`;

const StyledShortDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
  }
`;

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
`;

export default NewsCard;
