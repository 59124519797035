import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { prepareCategoriesFilter, prepareRangeFilterParams } from 'services/FilterParamsManager';
import { colors } from 'utils/colors';
import { SelectBox } from 'components/molecues/SelectBox/SelectBox';
import { ButtonTyped } from 'components/atoms/ButtonTyped/ButtonTyped';
import { maxDeviceSize } from 'utils/deviceSize';
import { FromToInput } from 'components/molecues/FromToInput/FromToInput';
import { getFormsData, logError } from 'services/ResourceApiManager';
import I18n, { getLanguage } from 'services/translation/I18n';
import { ReloadContext } from 'context/ReloadContext';

const SearchForm = ({ handleGetFilteredRealEstates }) => {
  const [PropertyTypes, setPropertyTypes] = useState([]);
  const [Locations, setLocations] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const { reload } = useContext(ReloadContext);

  const handleRealEstateFormData = () => {
    const lang = getLanguage();
    getFormsData({ lang })
      .then((response) => {
        const { data } = response;
        const [propertyTypes, locations] = data['hydra:member'];
        if (propertyTypes !== undefined) {
          propertyTypes.push({ value: '[propertyType]', label: 'Wszystkie' });
          setPropertyTypes(propertyTypes);
        }
        if (locations !== undefined) {
          locations.push({ value: '[location]', label: 'Wszystkie' });
          setLocations(locations);
        }
      })
      .catch((error) => {
        logError(error, 'SFHI-01');
        toast.error(<I18n trans="error.message.searchFormError" />, {
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
      });
  };

  useEffect(() => {
    handleRealEstateFormData();
  }, [reload]);

  const removeEmptyParams = (params) => {
    const newParams = { ...params };
    Object.keys(newParams).forEach(
      (key) => (newParams[key] === undefined || newParams[key] === '') && delete newParams[key],
    );
    return newParams;
  };

  const handleSearchParams = (e) => {
    let newFilterParams = { ...filterParams };
    if ('value' in e && 'label' in e) {
      newFilterParams = prepareCategoriesFilter(newFilterParams, e);
    } else if (e.value === '') {
      delete newFilterParams[e.target.id];
    } else {
      newFilterParams[e.target.id] = e.target.value;
    }
    newFilterParams = prepareRangeFilterParams(newFilterParams, 'price', e);
    newFilterParams = prepareRangeFilterParams(newFilterParams, 'area', e);
    newFilterParams = prepareRangeFilterParams(newFilterParams, 'roomCount', e);

    setFilterParams(removeEmptyParams(newFilterParams));
  };

  return (
    <SearchFormContainer onSubmit={(e) => handleGetFilteredRealEstates(e, filterParams)}>
      <StyledSubFormContainer>
        <SelectBox
          options={PropertyTypes}
          id="propertyTypes"
          name="propertyTypes"
          placeHolder="selectBox.propertyType"
          handleSearchParams={handleSearchParams}
        />
        <SelectBox
          options={Locations}
          id="locations"
          name="locations"
          placeHolder="selectBox.location"
          handleSearchParams={handleSearchParams}
        />
      </StyledSubFormContainer>
      <StyledSubFormContainer>
        <FromToInput
          idFrom="price[gte]"
          idTo="price[lte]"
          name="price"
          placeHolderFrom="fromToInput.priceFrom"
          placeHolderTo="fromToInput.priceTo"
          type="number"
          step={1}
          handleSearchParams={handleSearchParams}
        />
        <FromToInput
          idFrom="area[gte]"
          idTo="area[lte]"
          placeHolderFrom="fromToInput.areaFrom"
          placeHolderTo="fromToInput.areaTo"
          type="number"
          step={1}
          handleSearchParams={handleSearchParams}
        />
        <FromToInput
          idFrom="roomCount[gte]"
          idTo="roomCount[lte]"
          placeHolderFrom="fromToInput.roomsFrom"
          placeHolderTo="fromToInput.roomsTo"
          type="number"
          step={1}
          handleSearchParams={handleSearchParams}
        />
      </StyledSubFormContainer>
      <ButtonTyped text="searchForm.buttonTyped.find" />
    </SearchFormContainer>
  );
};

SearchForm.propTypes = {
  handleGetFilteredRealEstates: PropTypes.func.isRequired,
};

const SearchFormContainer = styled.form`
  padding: 1rem;
  color: ${colors.white};
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
`;

const StyledSubFormContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1rem;
  @media ${maxDeviceSize.tablet} {
    margin: 2rem;
    flex-direction: column;
  }
`;

export default SearchForm;
