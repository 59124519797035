import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Image } from 'components/molecues/Image/Image';
import { colors } from 'utils/colors';
// import { maxDeviceSize } from 'utils/deviceSize';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import LinkController from 'DDD/Link/Ui/LinkController';
import { pages } from 'constans/pages';
import { Button } from 'components/atoms/Button/Button';

const TeamCard = ({ id, fullName, src, description }) => {
  const linkController = new LinkController();
  const truncatedDescription =
    description.length > 100 ? `${description.substring(0, 100)}...` : description;

  return (
    <StyledCardContainer bgcolor={colors.lightBlack}>
      {!String(src).includes('undefined') && (
        <StyledImageContainer to={linkController.getRouteByName(pages.teamMemberDetail, { id })}>
          <Image
            src={src}
            alt={fullName}
            width="100%"
            height="100%"
            boxshadow="none"
            hover="false"
            style={{ border: 'none' }}
          />
        </StyledImageContainer>
      )}
      <StyledContent>
        <StyledTitle>{fullName}</StyledTitle>
        <StyledDescription>{parse(truncatedDescription || '')}</StyledDescription>
        <StyledButtonContainer>
          <Button
            href={linkController.getRouteByName(pages.teamMemberDetail, { id })}
            text="realEstateCard.showMore"
          />
        </StyledButtonContainer>
      </StyledContent>
    </StyledCardContainer>
  );
};

TeamCard.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  description: PropTypes.string,
};

TeamCard.defaultProps = {
  src: '',
  description: '',
};

const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 1px solid ${colors.rottenGreen};
  color: ${colors.white};
  background-color: ${({ bgcolor }) => bgcolor};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  max-width: 300px;
  margin: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  }
`;

const StyledImageContainer = styled(Link)`
  width: 100%;
  overflow: hidden;
  img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    transition: transform 0.3s ease;
  }
  &:hover img {
    transform: scale(1.05);
  }
`;

const StyledContent = styled.div`
  border-top: 1px solid ${colors.rottenGreen};
  padding: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.h2`
  font-size: 1.25rem;
  color: ${colors.darkBlack};
  margin-bottom: 0.5rem;
`;

const StyledDescription = styled.p`
  font-size: 0.9rem;
  color: ${colors.gray};
`;

const StyledButtonContainer = styled.div``;

export default TeamCard;
