import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import SearchForm from 'components/organisms/SearchForm/SearchForm';
import RealEstateCard from 'components/molecues/RealEstateCard/RealEstateCard';
import { maxDeviceSize } from 'utils/deviceSize';
import { getRealEstates, logError } from 'services/ResourceApiManager';
import { Loading } from 'components/atoms/Loading/Loading';
import PageWrapper from 'components/organisms/PageWrapper/PageWrapper';
import Pagination from 'components/molecues/Pagination/Pagination';
import { toast } from 'react-toastify';
import I18n, { getLanguage } from 'services/translation/I18n';
import { ReloadContext } from 'context/ReloadContext';
import { getImageUrl } from 'services/ImageProvider';

const RealEstate = () => {
  const [realEstates, setRealEstates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [realEstatesPerPage] = useState(6);
  const { reload } = useContext(ReloadContext);
  const lang = getLanguage();

  const getAllRealEstates = () => {
    getRealEstates({ lang })
      .then((response) => {
        const { data } = response;
        setLoading(false);
        setRealEstates(data['hydra:member']);
      })
      .catch((error) => {
        logError(error, 'REHI-01');
        toast.error(<I18n trans="error.message.realEstateDataError" />, {
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
      });
  };

  useEffect(() => {
    getAllRealEstates();
  }, [reload]);

  const indexOfLastRealEstate = currentPage * realEstatesPerPage;
  const indexOfFirstRealEstate = indexOfLastRealEstate - realEstatesPerPage;
  const currentRealEstates = realEstates.slice(indexOfFirstRealEstate, indexOfLastRealEstate);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleGetFilteredRealEstates = (e, filterParams) => {
    e.preventDefault();
    setLoading(true);
    getRealEstates({ ...filterParams, lang }).then((response) => {
      setLoading(false);
      const { data } = response;
      setRealEstates(data['hydra:member']);
    });
  };

  if (loading) {
    return (
      <PageWrapper title="pageWrapper.findRealEstate">
        <SearchForm handleGetFilteredRealEstates={handleGetFilteredRealEstates} />
        <Loading />
      </PageWrapper>
    );
  }

  if (realEstates.length === 0) {
    return (
      <PageWrapper title="pageWrapper.findRealEstate">
        <SearchForm handleGetFilteredRealEstates={handleGetFilteredRealEstates} />
        <StyledRealEstateWrapper>
          <h2>
            <I18n trans="realEstate.realEstate.notFound" />
          </h2>
        </StyledRealEstateWrapper>
      </PageWrapper>
    );
  }
  return (
    <PageWrapper title="pageWrapper.findRealEstate">
      <SearchForm handleGetFilteredRealEstates={handleGetFilteredRealEstates} />
      <StyledRealEstateWrapper>
        {
          // eslint-disable-next-line
          currentRealEstates &&
            currentRealEstates.map((realEstate) => (
              <RealEstateCard
                key={realEstate.uuid}
                id={realEstate.uuid}
                src={getImageUrl(realEstate.mainImage)}
                title={realEstate.title}
                price={String(realEstate.price)}
                roomsNumber={realEstate.roomCount}
                area={realEstate.area}
                currency={realEstate.currency}
              />
            ))
        }
      </StyledRealEstateWrapper>
      {realEstates.length > realEstatesPerPage && (
        <Pagination
          itemsPerPage={realEstatesPerPage}
          totalItems={realEstates.length}
          paginate={paginate}
        />
      )}
    </PageWrapper>
  );
};

const StyledRealEstateWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;

  @media ${maxDeviceSize.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export default RealEstate;
