import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { maxDeviceSize } from '../../../utils/deviceSize';
import { colors } from '../../../utils/colors';

export const Image = ({
  src,
  alt,
  width,
  height,
  style,
  fullwidth,
  bgcolor,
  boxshadow,
  small,
  hover,
}) => (
  <StyledImageContainer small={small} bgcolor={bgcolor}>
    <StyledImage
      src={src}
      loading="lazy"
      alt={alt}
      width={width}
      height={height}
      style={style}
      fullwidth={fullwidth}
      boxshadow={boxshadow}
      hover={hover}
    />
  </StyledImageContainer>
);

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  bgcolor: PropTypes.string,
  small: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  fullwidth: PropTypes.string,
  boxshadow: PropTypes.string,
  hover: PropTypes.string,
};

Image.defaultProps = {
  width: '400',
  height: '400',
  style: { border: '1px solid rgba(228, 183, 116, 0.74)' },
  bgcolor: colors.lightBlack,
  fullwidth: 'false',
  boxshadow: 'true',
  small: 'false',
  hover: 'true',
};

const StyledImage = styled.img`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  display: block;
  border: 2px solid ${colors.rottenGreen};
  opacity: 0.9;
  box-shadow: ${({ boxshadow }) =>
    boxshadow === 'true' ? '8px 8px 24px 0px rgba(66, 68, 90, 1)' : 'none'};
  &:hover {
    transform: ${({ hover }) => (hover === 'true' ? 'translateY(-2px)' : 'none')};
    opacity: 1;
  }

  @media ${maxDeviceSize.tablet} {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledImageContainer = styled.div`
  background-color: ${({ bgcolor }) => bgcolor};
  width: ${({ small }) => (small === 'true' ? '200px' : 'auto')};
  height: ${({ small }) => (small === 'true' ? '200px' : 'auto')};
  border-radius: 12px;

  @media ${maxDeviceSize.tablet} {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;
