import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import I18n from 'services/translation/I18n';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';

export const NavLinkItem = ({ href, alt, text, dropdown }) => (
  <StyledNavLinkWrapper to={href} alt={alt} dropdown={dropdown}>
    <StyleNavLinkContainer>
      <I18n trans={text} />
    </StyleNavLinkContainer>
  </StyledNavLinkWrapper>
);

NavLinkItem.propTypes = {
  href: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  dropdown: PropTypes.string,
};

NavLinkItem.defaultProps = {
  dropdown: 'false',
};

const StyledNavLinkWrapper = styled(Link)`
  display: flex;
  align-items: center;
  width: 50%;
  color: ${colors.white};
  padding: ${({ dropdown }) => (dropdown === 'true' ? '0' : '0.5rem')};
  color: ${colors.white};
  &:hover {
    cursor: pointer;
    color: ${colors.glamourGold};
  }

  @media ${maxDeviceSize.tablet} {
    display: ${({ dropdown }) => (dropdown === 'true' ? 'flex' : 'none')};
  }
`;

const StyleNavLinkContainer = styled.div`
  text-align: center;
  width: 100%;
`;
