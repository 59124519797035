import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { pl } from 'utils/languages/pl';
import { de } from 'utils/languages/de';

const LANG = 'language';
const DEFAULT_LANG = 'pl';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      pl: { translation: pl },
      de: { translation: de },
    },
    lng: window.localStorage.getItem(LANG) || DEFAULT_LANG,
    fallbackLng: window.localStorage.getItem(LANG) || DEFAULT_LANG,
    interpolation: { escapeValue: false },
  });

const I18n = ({ trans }) => {
  const { t } = useTranslation();
  return t(trans);
};

export const changeLanguage = (lang) => {
  i18n.changeLanguage(lang);
  window.localStorage.setItem(LANG, lang);
};

export const getLanguage = () => i18n.language;

export const translateString = (key) => {
  const { t } = useTranslation();
  return t(key);
};

export default I18n;
