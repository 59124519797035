import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ContactLink } from 'components/atoms/ContactLink/ContactLink';
import I18n from 'services/translation/I18n';

export const SocialMedia = ({ instagram, facebook }) => {
  if (instagram !== '' || facebook !== '') {
    return (
      <StyledSocialMediaWrapper>
        <StyledSocialMediaDescription>
          <I18n trans="contact.socialMediaDescription" />
        </StyledSocialMediaDescription>
        <StyledSocialMediaContainer>
          {facebook && (
            <ContactLink href={facebook} alt="Facebook" Icon={FaFacebookF} ariaLabel="Facebook" />
          )}
          {instagram && (
            <ContactLink
              href={instagram}
              alt="Instagram"
              Icon={FaInstagram}
              ariaLabel="Instagram"
            />
          )}
        </StyledSocialMediaContainer>
      </StyledSocialMediaWrapper>
    );
  }
  return <>&apos;&apos;</>;
};

SocialMedia.propTypes = {
  facebook: PropTypes.string,
  instagram: PropTypes.string,
};

SocialMedia.defaultProps = {
  facebook: '',
  instagram: '',
};

const StyledSocialMediaWrapper = styled.div``;
const StyledSocialMediaDescription = styled.p`
  text-align: center;
`;
const StyledSocialMediaContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
