import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import { Image } from 'components/molecues/Image/Image';
import { maxDeviceSize } from 'utils/deviceSize';
import I18n from 'services/translation/I18n';

const ValutationStep = ({ image, title, description }) => (
  <StyledStepContainer>
    <StyledContentWrapper>
      <Image
        src={image}
        alt={title}
        bgcolor="transparent"
        boxshadow="false"
        small="true"
        width="200"
        height="200"
        hover="false"
      />
      <StyledTextWrapper>
        <StyledStepTitle>
          <I18n trans={title} />
        </StyledStepTitle>
        <StyledStepDescription>
          <I18n trans={description} />
        </StyledStepDescription>
      </StyledTextWrapper>
    </StyledContentWrapper>
  </StyledStepContainer>
);

ValutationStep.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const StyledStepContainer = styled.div`
  padding: 2rem;
  border: 1px solid ${colors.glamourGold};
  margin: 1rem;
  box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
  width: 50%;
  border-radius: 12px;
  display: flex;
  justify-content: center;

  @media ${maxDeviceSize.tablet} {
    width: 80%;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
  }
`;

const StyledTextWrapper = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${maxDeviceSize.tablet} {
    margin-left: 0;
    text-align: center;
  }
`;

const StyledStepTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;

  @media ${maxDeviceSize.tablet} {
    margin-top: 0.5rem;
  }
`;

const StyledStepDescription = styled.p`
  font-size: 1rem;
  color: ${colors.darkGray};
`;

export default ValutationStep;
