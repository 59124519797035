import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Image } from 'components/molecues/Image/Image';
import { colors } from 'utils/colors';
import { DetailListItem } from 'components/atoms/DetailListItem/DetailListItem';
import { getImageUrl } from 'services/ImageProvider';

const TeamCardRealEstate = ({ firstName, lastName, imageSrc, email, phoneNumber }) => (
  <StyledCardContainer>
    <StyledImageContainer>
      <Image
        src={getImageUrl(imageSrc)}
        alt={`${firstName} ${lastName}`}
        width="100%"
        height="100%"
        boxshadow="none"
        hover="false"
        style={{ border: 'none' }}
      />
    </StyledImageContainer>
    <StyledContent>
      <StyledTitle>{`${firstName} ${lastName}`}</StyledTitle>

      {email && (
        <StyledInfo>
          <DetailListItem
            name="team.email"
            value={<StyledContact href={`mailto:${email}`}>{email}</StyledContact>}
          />
        </StyledInfo>
      )}
      {phoneNumber && (
        <StyledInfo>
          <DetailListItem
            name="team.phoneNumber"
            value={<StyledContact href={`tel:${phoneNumber}`}>{phoneNumber}</StyledContact>}
          />
        </StyledInfo>
      )}
    </StyledContent>
  </StyledCardContainer>
);

TeamCardRealEstate.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
};

TeamCardRealEstate.defaultProps = {
  email: '',
  phoneNumber: '',
};

const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${colors.rottenGreen};
  background-color: ${colors.grey};
  border-radius: 12px;
  overflow: hidden;
  max-width: 300px;
  margin-top: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  }
`;

const StyledImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  img {
    transition: transform 0.3s ease;
  }
  &:hover img {
    transform: scale(1.05);
  }
`;

const StyledContent = styled.div`
  padding: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.h2`
  font-size: 1.25rem;
  color: ${colors.darkBlack};
  margin-bottom: 0.5rem;
`;

const StyledInfo = styled.p`
  font-size: 0.9rem;
  color: ${colors.gray};
  margin: 0.25rem 0;
`;

const StyledContact = styled.a`
  color: ${colors.black};
  text-decoration: none;

  &:hover {
    color: ${colors.rottenGreen};
  }
`;

export default TeamCardRealEstate;
