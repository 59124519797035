import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Input } from '../../atoms/Input/Input';

export const FromToInput = ({
  idFrom,
  idTo,
  placeHolderFrom,
  placeHolderTo,
  type,
  step,
  handleSearchParams,
}) => (
  <StyledFromToInputContainer>
    <Input
      id={idFrom}
      placeHolder={placeHolderFrom}
      type={type}
      step={step}
      handleSearchParams={handleSearchParams}
    />
    <Input
      id={idTo}
      placeHolder={placeHolderTo}
      type={type}
      step={step}
      handleSearchParams={handleSearchParams}
    />
  </StyledFromToInputContainer>
);

FromToInput.propTypes = {
  idFrom: PropTypes.string.isRequired,
  idTo: PropTypes.string.isRequired,
  placeHolderFrom: PropTypes.string.isRequired,
  placeHolderTo: PropTypes.string.isRequired,
  type: PropTypes.string,
  step: PropTypes.number,
  handleSearchParams: PropTypes.func.isRequired,
};

FromToInput.defaultProps = {
  type: 'text',
  step: null,
};

const StyledFromToInputContainer = styled.div`
  display: flex;
  margin: 0.1rem 0.5rem 0.1rem 0.5rem;
  width: 100%;
`;
