import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SectionLayout from 'layouts/SectionLayout';
import { maxDeviceSize } from 'utils/deviceSize';
import { getTeams, logError } from 'services/ResourceApiManager';
import I18n, { getLanguage } from 'services/translation/I18n';
import TeamCard from 'components/molecues/TeamCard/TeamCard';
import { getImageUrl } from 'services/ImageProvider';
import { ReloadContext } from 'context/ReloadContext';
import { Button } from 'components/atoms/Button/Button';
import LinkController from 'DDD/Link/Ui/LinkController';
import { pages } from 'constans/pages';

const TeamView = () => {
  const linkController = new LinkController();
  const [teams, setTeams] = useState([]);
  const { reload } = useContext(ReloadContext);
  const lang = getLanguage();
  const [isMobile, setIsMobile] = useState(false);

  const handleTeams = () => {
    getTeams({ lang })
      .then((response) => {
        setTeams(response.data['hydra:member']);
      })
      .catch((error) => {
        logError(error, 'TVHI-01');
        toast.error(<I18n trans="error.message.teamDataError" />, {
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
      });
  };

  // Sprawdzamy czy widok jest mobilny
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Ustal, czy szerokość okna jest dla mobile
    };

    handleResize(); // Ustaw początkowy stan
    window.addEventListener('resize', handleResize); // Dodaj listenera do zmian rozmiaru

    return () => window.removeEventListener('resize', handleResize); // Czyszczenie listenera
  }, []);

  useEffect(() => {
    handleTeams();
  }, [reload]);

  if (teams.length === 0) {
    return null;
  }

  // Limit wyświetlanych osób w zależności od urządzenia
  const visibleTeams = isMobile ? teams.slice(0, 3) : teams.slice(0, 8);

  return (
    <SectionLayout>
      <StyledTitle>
        <I18n trans="team.title" />
      </StyledTitle>
      <StyledTeamContentWrapper isMobile={isMobile}>
        {visibleTeams.map((team) => (
          <TeamCard
            id={team.uuid}
            key={team.uuid}
            src={getImageUrl(team.avatar)}
            fullName={team.fullName}
            description={team.description}
          />
        ))}
      </StyledTeamContentWrapper>
      <ToastContainer />

      {(teams.length > 8 && !isMobile) || (teams.length > 3 && isMobile) ? (
        <StyledButtonWrapper>
          <Button href={linkController.getRouteByName(pages.team)} text="team.showAll" />
        </StyledButtonWrapper>
      ) : null}
    </SectionLayout>
  );
};

const StyledTeamContentWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? 'repeat(1, 1fr)' : 'repeat(4, 1fr)')};
  gap: 2rem;
  margin-top: 2rem;
  justify-items: center;

  @media ${maxDeviceSize.phone} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media ${maxDeviceSize.smallScreen} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledTitle = styled.h2`
  font-size: 2rem;
  padding: 0 0 0 2rem;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

export default TeamView;
