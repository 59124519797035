export const pages = {
  home: 'home',
  news: 'news',
  aboutUs: 'aboutUs',
  realEstate: 'realEstate',
  realEstateDetail: 'realEstateDetail',
  teamMemberDetail: 'teamMemberDetail',
  team: 'team',
  realEstateValuation: 'realEstateValuation',
  epcOrder: 'epcOrder',
  investitions: 'investitions',
  contact: 'contact',
  newsDetail: 'newsDetail',
};
